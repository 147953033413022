import $ from 'jquery';

jQuery(function($){

// console.log('hi from home');

// More
$('.home__text--more').hide();
$('#more').on('click', function(){
  if($(this).html() == 'More'){
    $('.selected-work').css('margin-top', '48px');
    $('.home__text--more').show("fast");
    $(this).html('Less');
  }else{
    $('.selected-work').css('margin-top', '1px');
    $('.home__text--more').hide();
    $(this).html('More');
  }
});

$('.portfolio__grid').gridderExpander({
  scroll: true,
  scrollOffset: 30,
  scrollTo: "listitem", // panel or listitem
  animationSpeed: 400,
  animationEasing: "easeInOutExpo",
  showNav: true, // Show Navigation
  nextText: "", // Next button text
  prevText: "", // Previous button text
  closeText: "", // Close button text
  onStart: function () {
      //Gridder Inititialized
      //console.log('On Gridder Initialized...');
  },
  onContent: function () {
      //Gridder Content Loaded
      //console.log('On Gridder Expand...');
  },
  onClosed: function () {
      //Gridder Closed
      //console.log('On Gridder Closed...');
  }
});

});
